import React from "react";
import { Outlet } from "react-router-dom";
import { Button, Nav, NavbarBrand, NavLink } from "reactstrap";
import { useAuth } from "./Context/AuthContext";

export default function Menu() {
    const { logout } = useAuth();
    return (
        <div>
            <div style={{ background: "rgb(51, 51, 51)", height: "6vh" }}>
                <Nav
                    justified
                    pills
                    className="container-fluid"
                    style={{
                        paddingLeft: "10%",
                        paddingRight: "10%",
                        marginTop: "-5px",
                    }}
                >
                    <NavbarBrand>
                        <NavLink href="/">
                            <p
                                style={{
                                    color: "rgb(188, 188, 188)",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                }}
                            >
                                Haippy Together
                            </p>
                        </NavLink>
                    </NavbarBrand>
                    <Nav className="ms-auto" style={{ marginTop: "5px" }}>
                        <NavLink
                            style={{ color: "rgb(188, 188, 188)" }}
                            href="/dashboard"
                        >
                            Wish
                        </NavLink>
                        <NavLink
                            style={{ color: "rgb(188, 188, 188)" }}
                            href="/feeling"
                        >
                            Feeling
                        </NavLink>
                        <NavLink
                            style={{ color: "rgb(188, 188, 188)" }}
                            href="/memories"
                        >
                            Memories
                        </NavLink>
                        <NavLink
                            style={{ color: "rgb(188, 188, 188)" }}
                            href="/poem"
                        >
                            Poem
                        </NavLink>
                        <NavLink></NavLink>
                        <NavLink></NavLink>
                        <NavLink>
                            <Button
                                color=""
                                size="sm"
                                style={{
                                    color: "rgb(100, 100, 100)",
                                    paddingTop: 0,
                                }}
                                onClick={() => {
                                    logout();
                                }}
                            >
                                Log Out
                            </Button>
                        </NavLink>
                    </Nav>
                </Nav>
            </div>
            <Outlet />
        </div>
    );
}
