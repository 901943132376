import { Navigate } from "react-router-dom";
import SignIn from "./Components/Authentication/SignIn";
import Menu from "./Menu";
import Dashboard from "./Pages/Dashboard";
import Feeling from "./Pages/Feeling";
import Memories from "./Pages/Memories";
import WeBeenFor from "./Pages/WeBeenFor";
import Poem from "./Pages/Poem";

const routes = (isLoggedIn) => {
    if (isLoggedIn) {
        console.log("asd", isLoggedIn);
        return [
            {
                path: "/",
                element: <Menu />,
                children: [
                    { path: "/", element: <WeBeenFor /> },
                    { path: "/dashboard", element: <Dashboard /> },
                    { path: "/memories", element: <Memories /> },
                    { path: "/feeling", element: <Feeling /> },
                    { path: "/poem", element: <Poem /> },
                    { path: "*", element: <Navigate to="/" /> },
                ],
            },
        ];
    }
    return [
        {
            path: "/",
            element: <SignIn />,
            children: [{ path: "*", element: <Navigate to="/" /> }],
        },
    ];
};

export default routes;
