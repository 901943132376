import React from "react";
import { Row } from "reactstrap";

export default function Poem() {
    return (
        <div>
            <Row className="mt-5">
                <h2 className="text-center mb-4">
                    Life is falling apart <br />
                    Like a little kid, i am crying so much
                    <br />
                    <br />
                    I wonder what it would be like
                    <br />
                    If i didn't choose this path <br />
                    <br />
                    Future is meaningless without her <br />
                    Feel like i have no reason to live <br />
                    <br />
                    Try to be better person <br />
                    That’s hard for some reason <br />
                    <br />
                    Hope it gets better soon <br />
                    Hope she would come back <br />
                    <br />
                    Love you forever and unconditionally <br />
                    Looks like i am never gonna change <br />
                    <br />
                    This is the path i choose <br />
                    Thinking if i made a right choice <br />
                    <br />
                </h2>
            </Row>
        </div>
    );
}
