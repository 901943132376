import React from "react";
import { Card, CardBody, CardTitle, Container, Table } from "reactstrap";
import GenericModal from "../Modal/Modal";
import { firestore } from "../../FireBase/Firebase";
import { doc, deleteDoc } from "firebase/firestore";
import Moment from "moment";

export default function List({ name, type, list }) {
    if (!list) list = [];

    const handleDelete = async (item) => {
        await deleteDoc(doc(firestore, "feeling", item.id));
        window.location.href = "/feeling";
    };

    return (
        <Container>
            <Card>
                <CardTitle>
                    <h2 className="text-center">{name}</h2>
                </CardTitle>
                <CardBody>
                    <Table>
                        <thead>
                            <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">Хүсэл</th>
                                <th className="border-0">Emoji</th>
                                <th className="border-0">Огноо</th>
                                {type === 1 && (
                                    <th className="border-0">Устгах</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{item.description}</td>
                                        <td>{item.emoji}</td>
                                        <td>
                                            {Moment.utc(
                                                item.date.toDate()
                                            ).format("YYYY-MM-DD")}
                                        </td>
                                        {type === 1 && (
                                            <td>
                                                <GenericModal
                                                    onClick={() => {
                                                        handleDelete(item);
                                                    }}
                                                    action="Устгах"
                                                    title="Итгэлтэй байна уу?"
                                                    body="Та устгахдаа итгэлтэй байна уу?"
                                                />
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Container>
    );
}
