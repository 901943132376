import React, { useState } from "react";
import { Button } from "reactstrap";

export default function PlusButton({ onClick }) {
    const [isHover, setHover] = useState(false);

    const style = {
        display: "flex",
        color: "green",
        background: "white",
        position: "absolute",
        right: "50px",
        bottom: "50px",
        borderRadius: "100%",
        border: "2px solid green",
        height: "50px",
        width: "50px",
        justifyContent: "center",
        alignItems: "center",
    };

    const hoverStyle = {
        display: "flex",
        color: "white",
        background: "green",
        position: "absolute",
        right: "50px",
        bottom: "50px",
        borderRadius: "100%",
        border: "2px solid white",
        height: "50px",
        width: "50px",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div>
            <Button
                onClick={onClick}
                style={isHover === false ? style : hoverStyle}
                onMouseEnter={() => {
                    setHover(true);
                }}
                onMouseLeave={() => {
                    setHover(false);
                }}
            >
                +
            </Button>
        </div>
    );
}
