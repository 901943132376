import React, { useState } from "react";
import { Image, Modal } from "react-bootstrap";
import { Col, Row, Button } from "reactstrap";

export default function Grid({ images }) {
    const [sel, setSel] = useState(null);
    const [style, setStyle] = useState();

    const withOpacity = {
        background: "rgba(0, 0, 0, 0.6)",
        opacity: 0.5,
    };
    const noOpacity = {
        opacity: 1,
    };

    return (
        <div>
            <Row>
                {images.length > 0 &&
                    images.map((image, i) => {
                        return (
                            <Col md="4" sm="6" lg="3" className="ll-3">
                                <Row className="mt-3">
                                    <Button
                                        style={
                                            style === i
                                                ? withOpacity
                                                : noOpacity
                                        }
                                        onClick={() => {
                                            setSel(i);
                                        }}
                                        color=""
                                        onMouseEnter={() => {
                                            setStyle(i);
                                        }}
                                        onMouseLeave={() => {
                                            setStyle(null);
                                        }}
                                    >
                                        <Image
                                            width={"100%"}
                                            height={"100%"}
                                            src={image}
                                        />
                                    </Button>
                                </Row>
                            </Col>
                        );
                    })}
            </Row>
            <Modal
                style={{ fontSize: "13px" }}
                show={sel !== null}
                onHide={() => {
                    setSel(null);
                }}
            >
                <Modal.Header closeButton>Зураг</Modal.Header>
                <Modal.Body>
                    <Image width={"100%"} height={"100%"} src={images[sel]} />
                </Modal.Body>
            </Modal>
        </div>
    );
}
