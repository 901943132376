import React, { useRef, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import {
    Card,
    CardBody,
    Form,
    Button,
    FormGroup,
    Label,
    Input,
    Container,
} from "reactstrap";

import { useAlert } from "react-alert";
export default function SignIn() {
    const { login } = useAuth();
    const emailRef = useRef();
    const passwordRef = useRef();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        try {
            console.log(emailRef.current.value, passwordRef.current.value);
            const sth = await login(
                emailRef.current.value,
                passwordRef.current.value
            );
            console.log(sth);
        } catch (err) {
            alert.show("Нэвтэрч чадсангүй, дахин оролдоно уу!");
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
        >
            <div className="w-100" style={{ maxWidth: "400px" }}>
                <Card>
                    <CardBody>
                        <h2 className="text-center mb-4">Login</h2>
                        <Form>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    type="email"
                                    innerRef={emailRef}
                                    required
                                ></Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input
                                    innerRef={passwordRef}
                                    type="password"
                                    required
                                ></Input>
                            </FormGroup>
                            <Button
                                onClick={handleSubmit}
                                color={isLoading === false ? "primary" : ""}
                                className="w-100"
                            >
                                Log In
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Container>
    );
}
