import { addDoc, collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Col, Container, Form, Input, Row } from "reactstrap";
import { firestore } from "../FireBase/Firebase";
import PlusButton from "../Components/PlusButton";
import { useAuth } from "../Context/AuthContext";
import List from "../Components/Feeling/List";
import Calendar from "../Components/Feeling/Calendar";

/*
    Date: new Date();
    Feeling: Rate from few emojis;
    Description: String;
    assignee: Nana@haippy.mn | Badral@haippy.mn
*/

export default function Feeling() {
    const [view, setView] = useState(0); //0 for list, 1 for calendar
    const { currentUser } = useAuth();

    const [isCreateShow, setIsCreateShow] = useState(false);
    const [newFeelingDesc, setFeelingDesc] = useState("");
    const [newFeelingEmoji, setFeelingEmoji] = useState([]);
    const [feelings1, setFeelings1] = useState([]);
    const [feelings2, setFeelings2] = useState([]);

    const feelingCollection = collection(firestore, "feeling");

    const arr =
        currentUser.email === "badral@haippy.mn"
            ? ["Badral", "Nana"]
            : ["Nana", "Badral"];

    useEffect(() => {
        if (!currentUser) {
            window.location.href = "/signIn";
        }
        async function start() {
            const querySnapshot = await getDocs(feelingCollection);
            const docs1 = [];
            const docs2 = [];

            querySnapshot.forEach((doc) => {
                if (currentUser.email === doc.data().assignee)
                    docs1.push({ id: doc.id, ...doc.data() });
                else docs2.push({ id: doc.id, ...doc.data() });
            });

            docs1.sort((sth1, sth2) => {
                if (sth1.date > sth2.date) return -1;
                else if (sth1.date < sth2.date) return 1;
                else return 0;
            });

            docs2.sort((sth1, sth2) => {
                if (sth1.date > sth2.date) return -1;
                else if (sth1.date < sth2.date) return 1;
                else return 0;
            });
            setFeelings1(docs1);
            setFeelings2(docs2);
        }
        start();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createFeeling = async () => {
        await addDoc(feelingCollection, {
            description: newFeelingDesc,
            emoji: newFeelingEmoji,
            assignee: currentUser.email,
            date: new Date(),
        });
        window.location.href = "/feeling";
    };

    const handleClose = () => {
        setIsCreateShow(false);
    };

    return (
        <div>
            <PlusButton
                onClick={() => {
                    setIsCreateShow(true);
                }}
            />
            <Container>
                <Col>
                    <Row className="mt-3">
                        <Col md="5"></Col>
                        <Col md="2" className="text-center">
                            <h1>Feelings</h1>
                        </Col>
                        <Col md="5"></Col>
                    </Row>
                    <Row className="mt-1">
                        <Col md="10"></Col>
                        <Col md="2">
                            <div>
                                <Button
                                    size="sm"
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                        setView(0);
                                    }}
                                >
                                    <i class="fas fa-list"></i>
                                </Button>
                                <Button
                                    size="sm"
                                    onClick={() => {
                                        setView(1);
                                    }}
                                >
                                    <i class="far fa-calendar-alt"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            {view === 0 ? (
                                <List name={arr[0]} type={1} list={feelings1} />
                            ) : (
                                <Calendar
                                    name={arr[0]}
                                    type={1}
                                    list={feelings1}
                                />
                            )}
                        </Col>
                        <Col>
                            {view === 0 ? (
                                <List name={arr[1]} type={2} list={feelings2} />
                            ) : (
                                <Calendar
                                    name={arr[1]}
                                    type={2}
                                    list={feelings2}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Container>

            <Modal
                style={{ fontSize: "13px" }}
                show={isCreateShow}
                onHide={handleClose}
            >
                <Modal.Header closeButton>Feeling Үүсгэх</Modal.Header>
                <Modal.Body>
                    <Form>
                        <Input
                            placeholder="Тайлбар"
                            onChange={(e) => {
                                setFeelingDesc(e.target.value);
                            }}
                        ></Input>
                        <div style={{ height: "10px", width: "1px" }}></div>
                        <Input
                            placeholder="Emoji"
                            onChange={(e) => {
                                setFeelingEmoji(e.target.value);
                            }}
                        ></Input>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md="10"></Col>

                        <Col md="1">
                            <Button
                                size="sm"
                                color="primary"
                                onClick={createFeeling}
                            >
                                Үүсгэх
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
