import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import Grid from "../Components/Memories/Grid";
import PlusButton from "../Components/PlusButton";
import { storage } from "../FireBase/Firebase";

export default function Memories() {
  const [isCreateShow, setIsCreateShow] = useState(false);
  const imageRef = useRef();
  const imageNameRef = useRef();
  const [imgs, setImgs] = useState([]);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const uploadImage = async () => {
    console.log("working,  I guess", imageRef.current.files);
    if (imageRef.current.files[0]) {
      const name =
        imageNameRef === null ? makeid(12) : imageNameRef.current.value;
      const reff = ref(storage, `our_photos/${name}`);
      await uploadBytes(reff, imageRef.current.files[0]);
      window.location.href = "/memories";
    }
  };
  
  const start = async () => {
    const listRef = ref(storage, "/our_photos/");
    const res = await listAll(listRef);
    const sth = await Promise.all(
      res.items.map(async (itemRef, i) => {
        console.log(i, itemRef);
        const link = getDownloadURL(itemRef);
        return link;
      })
    );
    sth.sort((sth1, sth2) => {
      if (sth1.date > sth2.date) return 1;
      else if (sth1.date < sth2.date) return -1;
      else return 0;
    });
    setImgs(sth);
  };
  useEffect(() => {
    start();
  }, []);

  return (
    <div>
      <Card>
        <CardBody>{imgs.length > 0 ? <Grid images={imgs} /> : <></>}</CardBody>
      </Card>
      <Modal
        style={{ fontSize: "13px" }}
        show={isCreateShow}
        onHide={() => {
          setIsCreateShow(false);
        }}
      >
        <Modal.Header closeButton>Зураг нэмэх</Modal.Header>
        <Modal.Body>
          <Form>
            <Input type="file" innerRef={imageRef}></Input>
            <Input
              className="mt-3"
              placeholder="Нэр"
              innerRef={imageNameRef}
            ></Input>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md="10"></Col>

            <Col md="1">
              <Button size="sm" color="primary" onClick={uploadImage}>
                Upload!
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <PlusButton
        onClick={() => {
          setIsCreateShow(true);
        }}
      />
    </div>
  );
}
