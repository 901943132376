import React, { useState } from "react";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FormGroup, FormLabel, Modal } from "react-bootstrap";
import { Row, Col, Form, Input, Button } from "reactstrap";
import { deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../../FireBase/Firebase";
import moment from "moment";

export default function Calendar({ name, list, type }) {
    const [isCreateShow, setIsCreateShow] = useState(false);
    const [event, setEvent] = useState("");
    let newList = [];

    if (list && list.length > 0) {
        newList = list.map((item, index) => {
            console.log(item);
            return {
                title: index,
                date: moment.utc(item.date.toDate()).format("YYYY-MM-DD"),
            };
        });
    }

    function handleEventClick({ event }) {
        setEvent(list[event.title]);
        setIsCreateShow(true);
    }

    async function deleteFeeling() {
        await deleteDoc(doc(firestore, "feeling", event.id));
        window.location.href = "/feeling";
    }

    function renderEventContent(eventInfo) {
        console.log("event", eventInfo);
        let title = list[eventInfo.event.title].description;
        title = title.length > 10 ? title.substr(0, 10) + "..." : title;
        return (
            <>
                <b>{title}</b>
            </>
        );
    }

    return (
        <div>
            <Col>
                <Row>
                    <h2 className="text-center">{name}</h2>
                </Row>
                <Row>
                    <Col md="1"></Col>
                    <Col md="10">
                        <FullCalendar
                            headerToolbar={{
                                left: "prev,next today",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay",
                            }}
                            selectable={true}
                            eventClick={handleEventClick}
                            plugins={[
                                dayGridPlugin,
                                timeGridPlugin,
                                interactionPlugin,
                            ]}
                            initialView="dayGridMonth"
                            eventContent={renderEventContent}
                            events={newList}
                        />
                    </Col>
                    <Col md="1"></Col>
                </Row>
            </Col>

            <Modal
                style={{ fontSize: "13px" }}
                show={isCreateShow}
                onHide={() => {
                    setIsCreateShow(false);
                }}
            >
                <Modal.Header closeButton>
                    <b>Feeling Detail</b>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup>
                            <FormLabel>
                                <b>Feeling</b>
                            </FormLabel>
                            <Input
                                type="textarea"
                                value={event.description}
                                disabled
                            ></Input>
                        </FormGroup>
                        <div style={{ height: "10px", width: "1px" }}></div>

                        <FormGroup>
                            <FormLabel>
                                <b>Emoji</b>
                            </FormLabel>
                            <Input value={event.emoji} disabled></Input>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                {type === 1 ? (
                    <Modal.Footer>
                        <Row>
                            <Col md="10"></Col>

                            <Col md="1">
                                <Button
                                    size="sm"
                                    color="danger"
                                    onClick={deleteFeeling}
                                >
                                    Устгах
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                ) : (
                    <></>
                )}
            </Modal>
        </div>
    );
}
