import React from "react";
import { Row } from "reactstrap";

export default function WeBeenFor() {
  const date1 = new Date(1631200000000);
  const date2 = new Date("2024-07-10");

  const diff = date2 - date1;

  const count = Math.floor(diff / 1000 / 60 / 60 / 24);

  return (
    <div>
      <Row className="mt-5">
        <h2 className="text-center mb-4">
          We were together for {count} days. &#128532;
        </h2>
      </Row>
    </div>
  );
}
