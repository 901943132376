import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";

import { auth } from "../FireBase/Firebase";
import routes from "../routes";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider() {
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("current-user"))
    );

    function login(email, password) {
        const sth = signInWithEmailAndPassword(auth, email, password);
        return sth;
    }

    function logout() {
        const sth = signOut(auth);
        return sth;
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(
            (user) => {
                setCurrentUser(user);
                localStorage.setItem("current-user", JSON.stringify(user));
            },
            (err) => {
                console.error(err);
            }
        );
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        login,
        logout,
    };

    const routing = useRoutes(routes(currentUser !== null));
    return <AuthContext.Provider value={value}>{routing}</AuthContext.Provider>;
}
